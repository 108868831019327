<template>
  <div>
    <div class="body-box">
      <div style="width: 200px;float:left;">
        <el-menu
            :default-active="selectName"
            class="el-menu-vertical-demo"
            style="text-align: center"
            @select="handleOpen"
        >
          <el-menu-item index="1">
            <span slot="title">待检批次</span>
          </el-menu-item>
          <el-menu-item index="2">
            <span slot="title">检验结果</span>
          </el-menu-item>
        </el-menu>
      </div>
      <div class="agricul_right ib">
        <div v-if="selectName == 1">
          <detection_batch></detection_batch>
        </div>
        <div v-if="selectName == 2">
          <detection_result :farmingCapitalTypeList="farmingCapitalTypeList"></detection_result>
        </div>
      </div>
      <div class="clear"></div>
    </div>
  </div>
</template>

<script>
import detection_batch  from '@/components/checkout/detection_batch'
import detection_result  from '@/components/checkout/detection_result'


export default {
  data() {
    return {
      selectName:  localStorage.getItem('selectIndex'),
      farmingCapitalTypeList:[]
    }
  },
  components:{
    detection_batch,
    detection_result,
  },
  mounted() {
    this.$api.post('farmingCapitalType/select', {}, res => {
      console.log(res, '农资类型下拉')
      if (res.code === 0) {
        this.farmingCapitalTypeList = res.data
      }
    })
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
      this.selectName = key
    },
  }
}
</script>

<style scoped>
.body-box {
  margin-top: 20px;
}

.ib {
  /*display: inline-block;*/
}

.agricul_right {
  margin-left: 10px;
  width: 990px;

  background: #FFFFFF;
  float: left;
}

.clear {
  clear: both
}
</style>
