<template>
  <div >
    <div class="head-name-title" style="margin-left: 20px">检验结果</div>
    <div style="margin:20px 0 30px 20px ">
      <el-button type="primary"  @click="dialogVisible = true">上传检验结果</el-button>
      <el-dialog
          title=""
          :visible.sync="dialogVisible"
          width="30%" >
        <div style="height: 460px;width: 400px;text-align: center;margin-left: 80px">
          <div class="dialog-title">上传检验结果</div>
          <el-form  label-width="100px">

            <el-form-item label="农资分类">
              <el-select v-model="farmingCapitalTypeVal" placeholder="请选择" style="width: 300px;"
                         @change="getSelectfarmingCapitalType">
                <el-option
                    v-for="item in farmingCapitalTypeList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="农资名称">
              <el-select v-model="farmingCapitalVal" placeholder="请选择" style="width: 300px;" @change="getUnti">
                <el-option
                    v-for="item in farmingCapital"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="实测值">
              <el-input v-model="num" style="width: 260px;"></el-input> {{unit}}
            </el-form-item>
            <el-form-item label="结论">
              <el-radio-group v-model="radio">
                <el-radio :label="1">合格</el-radio>
                <el-radio :label="2">不合格</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="批次号">
              <el-input v-model="num"></el-input>
            </el-form-item>
          </el-form>
          <el-col>
            <el-button class="dialog-button" @click="update">提交</el-button>
          </el-col>
        </div>
      </el-dialog>
    </div>
    <div >
      <el-table
          :data="tableData"
          border
          style="width: 918px;margin-left: 36px;margin-bottom: 30px"
          :row-class-name="tableRowClassName">
        <el-table-column
            prop="gname"
            label="序号" >
        </el-table-column>
        <el-table-column
            prop="dname"
            label="产品类别"
        >
        </el-table-column>
        <el-table-column
            prop="cond"
            label="农产品名称">
        </el-table-column>
        <el-table-column
            prop="val1"
            label="检验项目">
        </el-table-column>
        <el-table-column
            prop="val1"
            label="实测值">
        </el-table-column>
        <el-table-column
            prop="date"
            label="结论">
        </el-table-column>
        <el-table-column
            prop="createTime"
            label="检测时间">
        </el-table-column>
        <el-table-column
            prop="createTime"
            label="批次号">
        </el-table-column>
        <el-table-column
            label="操作" width="140">
          <template slot-scope="scope"  >
            <a  style="cursor: pointer;color:#1A99F2 "
                @click="handleEdit(scope.$index, scope.row)">关联批次号
            </a>

            <el-popconfirm style="margin-left: 10px"
                           title="确定删除吗？"
                           @confirm="handleDelete(scope.$index, scope.row)"
            >
              <a   style="cursor: pointer;color:#1A99F2 "
                   type="danger"
                   slot="reference"
              >删除
              </a>
            </el-popconfirm>
          </template>
        </el-table-column>


      </el-table>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    farmingCapitalTypeList: {
      type: Array,
      defalut: []
    }
  },
  data() {
    return {
      num:'',
      value:'',
      radio:'',
      unit:'单位',
      dialogVisible: false,
      tableData: [],
      farmingCapital: [],
      farmingCapitalTypeVal:'',
      farmingCapitalVal:''
    }
  },
  mounted() {
    localStorage.setItem('selectIndex','2')
  },
  methods: {
    // 第一个下拉框选择
    getSelectfarmingCapitalType(res) {
      console.log(res,)
      let params = {
        "typeId": res
      }
      this.$api.post('farmingCapital/select', params, res => {
        console.log(res, '选中农资分类返回农资名字')
        if (res.code === 0) {
          this.farmingCapital = res.data
        }

      })
    },
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
    getUnti(res) {
      console.log(res, 'sss')

      let idx = this.farmingCapital.findIndex((item) => item.id == res)
      console.log(idx, 'idx')
      this.unit = this.farmingCapital[idx].unit
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    update() {
      let params = {
        "farmingTypeId": this.farmingCapitalTypeVal,
        "farmingId": this.farmingCapitalVal,
        "num": this.num,
        "remark": this.remark,
      }

      this.$api.post('farmingPurchase/saveApply', params, res => {
        console.log(res, '采购申请数据返回')
        if (res.code === 0) {
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'success',
            onClose: () => {
              this.dialogVisible = false
              this.reload()
              this.$emit('changeSelectIndex','1')
            }

          });
        }

      })
    },
    handleEdit(index, row) {  // 修改按钮
      console.log(index, row.id);
      let params = {
        "id":row.id,
      }
      this.$api.post('farmingStoreIn/confirmIn', params, res => {
        console.log(res, '确认入库数据返回')
        if (res.code === 0) {
          this.$message({
            showClose: true,
            duration:1500,
            message: res.msg,
            type: 'success',
            onClose:()=>{
              this.dialogVisible = false
              this.$emit('changeSelectIndex','4')
              this.reload()
            }
          });
        }

      })
    },
    handleDelete(index, row) {  //删除按钮
      console.log(index, row);
    },
  }
}
</script>

<style scoped>
.head-name-title {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  height: 57px;
  line-height: 57px;
  border-bottom: 2px solid #E8E8E8;
}
.table-title-name{
  font-size: 20px;
  font-weight: 400;
  color: #333333;
  margin: 20px 0 5px 36px;

}
.dialog-title {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  margin-bottom: 30px;
}
.dialog-button {
  height: 60px;
  background: #009400;
  border-radius: 30px;
  width: 400px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FEFEFE;
  margin-top: 30px;
}
</style>
