<template>
  <div>
    <div class="head-name-title" style="margin-left: 20px">待检批次</div>
    <div style="margin: 30px 0 30px 20px  ">
      <el-table
          :data="tableData"
          border
          style="width: 950px;font-size: 14px"
          :row-class-name="tableRowClassName">
        <el-table-column
            prop="gname"
            label="序号"
        >
        </el-table-column>
        <el-table-column
            prop="dname"
            label="农产品名称"
        >
        </el-table-column>
        <el-table-column
            prop="cond"
            label="大棚">
        </el-table-column>
        <el-table-column
            prop="val1"
            label="采摘任务发布时间">
        </el-table-column>
        <el-table-column
            prop="val1"
            label="任务执行人">
        </el-table-column>
        <el-table-column
            prop="date"
            label="批次号">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1517 弄'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1519 弄'
      }, {
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1516 弄'
      }]
    }
  },
  mounted() {
    localStorage.setItem('selectIndex','1')
  },
  methods: {
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
  }
}
</script>

<style scoped>
.head-name-title {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  height: 57px;
  line-height: 57px;
  border-bottom: 2px solid #E8E8E8;
}
</style>
